<template>
  <div>
    <h1>Grupper</h1>

    <div v-if="user.admin" class="my-2">
      <b-form-select v-model="period" :options="periods" />
    </div>

    <b-card-group columns>

      <GroupList
        :groups="groupsByCategory('adults')"
        :title="'Vuxna'"
        :showSchedule="user.admin"
        @schedule="editSchedule"
        />

      <GroupList
        :groups="groupsByCategory('youth')"
        :title="'Unga'"
        :showSchedule="user.admin"
        @schedule="editSchedule"
        />

      <GroupList
        :groups="groupsByCategory('other')"
        :title="'Övriga pass'"
        :showSchedule="user.admin"
        @schedule="editSchedule"
        />
      
    </b-card-group> 
    <b-modal id="edit-group-schedule-modal" title="Schema för gruppen" cancel-title="Avbryt" :busy="saving" @ok="saveEditedSchedule()" @cancel="resetForm()" size="lg">
      <b-container v-if="editedGroup" fluid>
        <b-row>
          <b-col>
            <h4>{{ editedGroup.name }}</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b>Dag</b>
          </b-col>
          <b-col cols="3">
            <b>Start</b>
          </b-col>
          <b-col cols="3">
            <b>Längd</b>
          </b-col>
        </b-row>
        <b-row v-for="(entry, index) in editedSchedule" :key="index">
          <b-col cols="4">
            {{ weekdays.find(w => w.value == entry.weekday).text }}
          </b-col>
          <b-col cols="3">
            {{ entry.startTime }}
          </b-col>
          <b-col cols="3">
            {{ entry.duration }}
          </b-col>
          <b-col>
            <font-awesome-icon :icon="['fas', 'calendar-minus']" ></font-awesome-icon>
          </b-col>
        </b-row>
	<b-row>
          <b-col cols="4">
            <b-form-select v-model="selectedWeekday" :options="weekdays"></b-form-select>
          </b-col>
          <b-col cols="3">
            <b-form-input v-model="selectedStartTime"></b-form-input>
          </b-col>
          <b-col cols="3">
            <b-form-input v-model="selectedDuration"></b-form-input>
          </b-col>
          <b-col>
            <font-awesome-icon :icon="['fas', 'calendar-plus']" @click="addScheduleEntry()"></font-awesome-icon>
          </b-col>
	</b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { db } from '@/firebaseConfig'
  import GroupList from '../components/GroupList.vue'
  import { doc, updateDoc } from 'firebase/firestore'

  export default {
    created() {
      this.bindGroups().then(() => {
        console.log('Groups bound');
      }).catch((error) => {
        console.error('Failed to bind groups:', error);
      });
    },
    components: {GroupList},
    computed: {
      ...mapState('attendance', ['groups']),
      ...mapState('user', ['user'])
    },
    data() {
      return {
        period: 'ht-2024',
        periods: ['vt-2022', 'ht-2022', 'vt-2023', 'ht-2023', 'vt-2024', 'ht-2024'],
        editedGroup: null,
        editedSchedule: [],
        selectedWeekday: null,
        selectedStartTime: null,
        selectedDuration: null,
        saving: false,
        weekdays: [
          { value: 1, text: 'Måndag'},
          { value: 2, text: 'Tisdag'},
          { value: 3, text: 'Onsdag'},
          { value: 4, text: 'Torsdag'},
          { value: 5, text: 'Fredag'},
          { value: 6, text: 'Lördag'},
          { value: 0, text: 'Söndag'}]
      }
    },
    methods: {
      ...mapActions('attendance', ['bindGroups', 'unbindGroups']),
      groupsByCategory(category) {
        return this.groups
          .filter((group) => group.period === this.period)
          .filter((group) => group.category === category)
      },
      editSchedule(group) {
        this.editedGroup = group
        if (group.schedule) {
          this.editedSchedule = group.schedule.slice()
        } else {
          this.editedSchedule = []
        }
        this.$bvModal.show('edit-group-schedule-modal')
      },
      addScheduleEntry() {
        this.editedSchedule.push(
          {
            weekday: this.selectedWeekday,
            startTime: this.selectedStartTime,
            duration: this.selectedDuration
          }
        )
      },
      saveEditedSchedule() {
        updateDoc(doc(db, 'groups', this.editedGroup.id), { schedule: this.editedSchedule.slice() })
        .then(() => {
          this.resetForm()
          this.saving = false
          this.$bvModal.hide('edit-group-schedule-modal')
        })
        .catch((error) => {
          this.saving = false
          console.error('Failed to save schedule', error)
        })
        this.saving = true
      },
      resetForm() {
        this.selectedWeekday = null
        this.selectedStartTime = null
        this.selectedDuration = null
      }
    }
  }

</script>
