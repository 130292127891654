<template>
  <b-card no-body
        :header="title" bg-variant="dark"
        text-variant="light"
        :align="groups.length==0 ? 'center' : 'left'">
    <b-spinner v-if="groups.length == 0"></b-spinner> 
    <b-list-group flush>
      <b-list-group-item
	v-for="group in groups"
	:key="group.id"
	:group="group"
	:to="go(group)"
	class="d-flex justify-content-between align-items-center">
        {{ group.name }}
	<font-awesome-icon v-if="showSchedule" :icon="scheduleIcon(group)" @click.prevent="$emit('schedule', group)"></font-awesome-icon>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      groups: {
        type: Array,
        required: true,
      },
      showSchedule: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      go(group) {
        return { name: 'group', params: { id: group.id }}
      },
      scheduleIcon(group) {
        if (group.schedule && group.schedule.length) {
          return ['fas', 'calendar-check']
        }
        return ['fas', 'calendar']
      }
    }
  }
</script>
